import Link from "next/link";

export const LegalDocuments = () => {
  return (
    <div className="space-x-4 text-center text-sm text-gray-600 dark:text-gray-400">
      <Link
        href="https://agoradata.com/privacy-policy"
        className="transition-colors hover:text-primary hover:underline"
      >
        Privacy Policy
      </Link>
      <Link href="https://agoradata.com/terms" className="transition-colors hover:text-primary hover:underline">
        Terms of Use
      </Link>
      <Link
        href="https://agoradata.com/arbitration-agreement"
        className="transition-colors hover:text-primary hover:underline"
      >
        Arbitration Agreement
      </Link>
    </div>
  );
};
