interface DataLayerData {
  [key: string]: any;
  event_id: string;
  element_id: string;
  user_email: string;
}

export const pushToDataLayer = (data: DataLayerData) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const pageViewTrigger = (props: { [key: string]: any } = {}) => {
  return window.dataLayer?.push({
    event: "Page View",
    url: window.location.href,
    ...props,
  });
};
