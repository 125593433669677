export enum UserRole {
  Owner = "Owner",
  SalesManager = "Sales Manager",
  GeneralManager = "General Manager",
  GeneralSalesManager = "General Sales Manager",
  Comptroller = "Comptroller/Business Manager",
  FinanceDirector = "Finance Director",
  FinanceManager = "Finance Manager",
  Other = "Other",
}
