"use client";

import Image from "next/image";
import { IMG_ALTRUVO_LOGO, IMG_ALTRUVO_MEANING, IMG_HANDSHAKE_CAR_KEYS } from "./constants/ImgPath";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { pageViewTrigger } from "./utils/GTMUtils";
import { Button } from "@agoradata/ads";
import { LandingPageForm } from "./components/LandingPageForm";
import LandingPageSuccess from "./components/LandingPageSuccess";
import { FeatureCard } from "./components/LandingPageFeature";
import { extractFirstName } from "./utils/NameExtractor";
import { LegalDocuments } from "./components/LegalDocuments";
import { SocialMediaLinks } from "./components/SocialMediaLinks";
import { HandCoins, Package } from "@phosphor-icons/react";

export default function Home() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dealerName, setDealerName] = useState("");
  const [role, setRole] = useState("");
  const [isInterested, setIsInterested] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [signUpClicked, setSignUpClicked] = useState(false);

  useEffect(() => {
    pageViewTrigger();
  }, []);

  const handleSignUpClick = () => {
    setSignUpClicked(false);
    setTimeout(() => setSignUpClicked(true), 0);
  };

  return (
    <div className="container relative mx-auto flex min-h-screen w-full flex-col items-center justify-center text-gray-900 dark:text-white">
      {/* Header */}
      <header className="relative w-full py-6 sm:pl-6">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <Image src={IMG_ALTRUVO_LOGO} alt="ALTRUVO™ Logo" width={250} height={100} className="mx-auto md:ml-0" />
        </motion.div>
      </header>
      {/* Main Content */}
      <main className="flex w-full flex-col justify-center gap-12 p-6 md:gap-16 lg:pb-24 lg:pt-16">
        {!isSuccess ? (
          <>
            {/* Hero Section */}
            <motion.div
              className="flex flex-col items-center lg:flex-row lg:justify-between"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.5 }}
            >
              <div className="flex flex-col items-center justify-center lg:order-2 lg:ml-12 lg:w-1/2">
                <Image src={IMG_HANDSHAKE_CAR_KEYS} alt="Agora Logo" width={575} height={160} className="w-full" />
              </div>
              <div className="mt-8 lg:mt-0 lg:w-1/2">
                <h2 className="text-left text-3xl font-medium leading-relaxed md:text-4xl lg:text-5xl lg:leading-relaxed">
                  Unlock Smarter Lending Decisions with ALTRUVO™
                </h2>
                <p className="mt-4 max-w-2xl text-left text-lg font-light leading-relaxed text-grey-dark dark:text-white">
                  Transform your auto loan process with data-driven insights, transparency, and risk-based pricing.
                </p>
                <div className="pt-6">
                  <Button text="Sign Up Now" onClick={handleSignUpClick} />
                </div>
              </div>
            </motion.div>

            {/* Mission Section */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0, x: -100 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1, ease: "easeInOut" },
                },
              }}
              className="w-full"
            >
              <div className="flex flex-col items-center gap-12 pt-12">
                <div className="w-3/4 border-t border-grey-medium" />
                <Image src={IMG_ALTRUVO_MEANING} alt="ALTRUVO™ Meaning" width={600} height={200} />
                <p className="text-center text-lg font-bold uppercase text-grey-dark dark:text-white">Our Mission</p>
                <h1 className="text-center text-xl font-light text-grey-dark dark:text-white">
                  Delivering a transparent, reliable, and growth-focused finance solution.
                </h1>
                <div className="w-3/4 border-b border-grey-medium" />
              </div>
            </motion.div>

            {/* Features Section */}
            <div className="mt-12 flex flex-col items-center gap-12 lg:mt-16">
              <FeatureCard
                title="Maximize Profitability, Minimize Risk"
                description="ALTRUVO's™ pricing adapts to each customer’s risk profile, boosting loan performance. Aligning pricing with risk reduces defaults and enhances portfolio stability, driving long-term profitability."
                features={["Tailored risk-based pricing", "Lower default rates", "Boosts profitability"]}
                icon={<HandCoins className="text-secondary" weight="duotone" size={36} />}
              />
              <FeatureCard
                title="Deal-Ready Inventory"
                description="ALTRUVO's™ inventory upload feature empowers dealers to make data-driven decisions on each vehicle, enabling them to find the best matches for their customers quickly and confidently."
                features={["Streamlines vehicle selection", "Eliminates guesswork", "Speeds up deal structuring"]}
                icon={<Package className="text-secondary" weight="duotone" size={36} />}
              />
            </div>

            {/* Form Section */}
            <div className="pt-12">
              <LandingPageForm
                name={name}
                email={email}
                dealerName={dealerName}
                role={role}
                isInterested={isInterested}
                nameError={nameError}
                emailError={emailError}
                setName={setName}
                setEmail={setEmail}
                setDealerName={setDealerName}
                setRole={setRole}
                setIsInterested={setIsInterested}
                setNameError={setNameError}
                setEmailError={setEmailError}
                setIsSuccess={setIsSuccess}
                requestsFocus={signUpClicked}
              />
            </div>
          </>
        ) : (
          <LandingPageSuccess name={extractFirstName(name)} />
        )}
      </main>
      {/* Footer */}
      <footer className="mt-auto w-full rounded-t-xl py-12">
        <div className="container mx-auto px-4 md:px-6 lg:px-8">
          <div className="flex flex-col gap-4">
            <p className="mx-auto mb-4 max-w-3xl text-center text-sm font-light text-gray-600 dark:text-gray-400">
              <b>Disclaimer:</b> Terms contained herein are indicative and solely for discussion purposes. Agora makes
              no representation or warranty with respect to and assumes no responsibility for the accuracy or adequacy
              of this presentation as a tool for financial planning nor any by-product of such information thereof.
            </p>
            <LegalDocuments />
            <SocialMediaLinks />
            <p className="mx-auto mb-4 max-w-3xl text-center text-sm font-light text-gray-600 dark:text-gray-400">
              © 2024 Agora Data, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
