const img_base_path = (process.env.NEXT_PUBLIC_CDN_URL ?? "") + "/img/";

export const IMG_LAPTOP = img_base_path + "Laptop.svg";
export const IMG_CONTACT_US_GRAY = img_base_path + "ContactUsGray.png";
export const IMG_AUTOEDGE_IPHONE = img_base_path + "AutoEdgePhone.png";
export const IMG_AUTOEDGE_IPHONE_CUTOFF = img_base_path + "email-service/AutoEdgePhoneCutoff.png";
export const IMG_AGORA_LOGO = img_base_path + "agora-logo.png";
export const IMG_AGORA_LOGO_WHITE = img_base_path + "agora-logo-white.svg";
export const IMG_AGORA_TARGET = img_base_path + "agora-target.svg";
export const IMG_AGORA_CAPITAL_WHITE = img_base_path + "AgoraCapitalWhite.svg";
export const IMG_NO_ACCESS = img_base_path + "NoAccess.svg";
export const IMG_COMING_SOON = img_base_path + "ComingSoon.svg";
export const IMG_AGORA_CAPITAL_GREY = img_base_path + "AgoraCapital_Grey.png";
export const IMG_COMING_SOON_DASHBOARD_CARD = img_base_path + "ComingSoonDashboardCard.png";
export const IMG_KPIS_WELCOME = img_base_path + "KpisWelcome.svg";
export const IMG_CASH_FLOW_COMING_SOON = img_base_path + "CashFlowComingSoon.svg";
export const IMG_CASH_FLOW_MODAL_IMAGE_1 = img_base_path + "MCFPModalImage1.svg";
export const IMG_CASH_FLOW_MODAL_IMAGE_2 = img_base_path + "MCFPModalImage2.svg";
export const IMG_CASH_FLOW_MODAL_IMAGE_3 = img_base_path + "MCFPModalImage3.svg";
export const IMG_CHART_EMPTY = img_base_path + "ChartEmpty.svg";
export const IMG_HANDSHAKE_CAR_KEYS = img_base_path + "HandshakeCarKeys.svg";
export const IMG_ALTRUVO_MEANING = img_base_path + "AltruvoMeaning.svg";
export const IMG_ALTRUVO_LOGO = img_base_path + "AltruvoLogo.svg";
export const IMG_ALTRUVO_LOGO_WITH_SUBTITLE = img_base_path + "AltruvoLogoWithSubtitle.svg";
