import { CheckFat } from "@phosphor-icons/react";
import { FC, ReactNode } from "react";
import { motion } from "framer-motion";

interface FeatureCardProps {
  title: string;
  description: string;
  features: string[];
  icon: ReactNode;
}

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.42, 0, 0.58, 1],
      when: "beforeChildren",
    },
  },
};

const iconVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { type: "spring", stiffness: 70, duration: 0.8 } },
};

const titleVariants = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { type: "spring", stiffness: 70, duration: 0.8 } },
};

const descriptionVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8 } },
};

const featureVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.6, ease: "easeOut" },
  },
};

export const FeatureCard: FC<FeatureCardProps> = ({ title, description, features, icon }) => {
  return (
    <motion.div
      className="flex w-full flex-col gap-6 rounded-[30px] bg-white px-6 py-8 shadow-lg sm:w-3/4 sm:flex-row"
      initial="hidden"
      animate="visible"
      variants={cardVariants}
    >
      {/* Icon with motion */}
      <motion.div className="flex items-start" variants={iconVariants}>
        {icon}
      </motion.div>

      {/* Text Content */}
      <div className="flex-1">
        {/* Title sliding from the right */}
        <motion.h3 className="mb-2 text-xl font-medium tracking-wide text-black" variants={titleVariants}>
          {title}
        </motion.h3>

        {/* Description fading in */}
        <div className="flex flex-col justify-center pt-2 xl:flex-row xl:items-start xl:justify-start xl:gap-x-12">
          <motion.p className="mb-4 max-w-96 text-black" variants={descriptionVariants}>
            {description}
          </motion.p>

          {/* List of Features sliding in from left */}
          <motion.ul className="space-y-2 xl:pl-12">
            {features.map((feature, index) => (
              <motion.li
                key={index}
                className="flex items-center space-x-2"
                initial="hidden"
                animate="visible"
                variants={featureVariants}
              >
                <CheckFat weight="duotone" size={26} className="text-secondary" />
                <span className="pl-2 font-medium text-black">{feature}</span>
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </div>
    </motion.div>
  );
};
