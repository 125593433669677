import { FacebookLogo, LinkedinLogo } from "@phosphor-icons/react";
import Link from "next/link";

export const SocialMediaLinks = () => {
  return (
    <div className="flex justify-center space-x-6">
      <Link href="https://www.facebook.com/AGORAData/">
        <FacebookLogo className="text-xl text-primary transition-colors hover:text-primary dark:text-gray-400" />
      </Link>
      <Link href="https://www.linkedin.com/company/agora-data-inc.">
        <LinkedinLogo className="text-xl text-primary transition-colors hover:text-primary dark:text-gray-400" />
      </Link>
    </div>
  );
};
