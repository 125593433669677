"use client";

import React from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "app/hooks/useWindowSize";

export interface LandingPageSuccessProps {
  name: string;
}

export default function LandingPageSuccess({ name }: LandingPageSuccessProps) {
  const { width, height } = useWindowSize();

  return (
    <div id="landing-page-success-container" className="relative min-h-[600px]">
      <div className="pointer-events-none absolute inset-0 z-0 mt-5 flex h-full items-center justify-center overflow-hidden">
        <Confetti
          opacity={0.8}
          recycle={false}
          width={width}
          height={height}
          colors={["#0D9283", "#F4B94B", "#0D6186", "#09B56D", "#4B606B", "#C9D4D9"]}
        />
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="min-h-72 w-full rounded-lg border bg-white pb-5 pt-16 text-center shadow-lg dark:bg-gray-800">
          <h1 className="text-xl font-bold dark:text-white sm:text-3xl">
            Congrats, {name}! You&apos;re on the list 🎉
          </h1>
          <p className="mt-12 dark:text-white">No further action is required.</p>
        </div>
      </div>
    </div>
  );
}
