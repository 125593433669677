import { Button, FormField, FormLabel, Input } from "@agoradata/ads";
import React, { FC, FormEvent, useEffect, useState } from "react";
import useFormSubmission from "app/hooks/useFormSubmission";
import { UserRole } from "app/enums/UserRole";

export interface LandingPageFormProps {
  name: string;
  email: string;
  dealerName: string;
  role: string;
  isInterested: boolean;
  nameError: boolean;
  emailError: boolean;
  setName: (name: string) => void;
  setEmail: (email: string) => void;
  setDealerName: (dealerName: string) => void;
  setRole: (role: string) => void;
  setIsInterested: (isInterested: boolean) => void;
  setNameError: (nameError: boolean) => void;
  setEmailError: (emailError: boolean) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  requestsFocus: boolean;
}

export const LandingPageForm: FC<LandingPageFormProps> = ({
  name,
  email,
  dealerName,
  role,
  isInterested,
  nameError,
  emailError,
  setName,
  setEmail,
  setDealerName,
  setRole,
  setIsInterested,
  setNameError,
  setEmailError,
  setIsSuccess,
  requestsFocus,
}) => {
  const mutation = useFormSubmission();
  const [customRole, setCustomRole] = useState(role);

  const validateField = (value: string, setError: (error: boolean) => void) => {
    if (value.trim() === "") {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const handleFormSubmission = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isNameValid = validateField(name, setNameError);
    const isEmailValid = validateField(email, setEmailError);

    if (!isNameValid || !isEmailValid) {
      return;
    }

    const usrModel = { name, email, dealerName, role: customRole, isInterested };
    mutation.mutate(usrModel);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setIsSuccess(true);
    }
  }, [mutation.isSuccess, setIsSuccess]);

  useEffect(() => {
    if (requestsFocus) {
      const element = document.querySelector("input");
      if (element) {
        element.focus();
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [requestsFocus]);

  const formFields = [
    { label: "Name*", value: name, onChange: setName, error: nameError, type: "text" },
    { label: "Email*", value: email, onChange: setEmail, error: emailError, type: "email" },
    { label: "Dealership Name", value: dealerName, onChange: setDealerName, type: "text" },
  ];

  const roles = Object.values(UserRole);
  const roleOptions = roles.map((role) => ({ label: role, value: role }));

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (roles.includes(selectedValue as UserRole)) {
      setRole(selectedValue);
      setCustomRole(selectedValue);
    } else {
      setRole(UserRole.Other);
      setCustomRole(UserRole.Other);
    }
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <form
        name="landing-page-form"
        id="landing-page-form"
        className="flex w-full flex-col items-center justify-center rounded-[30px] bg-white p-12 shadow-lg sm:w-3/4"
        onSubmit={handleFormSubmission}
      >
        <div className="grid gap-4">
          <div className="col-span-1 flex flex-col gap-2">
            <h2 className="pb-3 pt-4 dark:text-white">Sign up for free access</h2>
            <p className="dark:text-white">And receive updates and get early access to our launch. </p>
          </div>
          <div className="relative grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2">
            {formFields.map(({ label, value, onChange, error, type }) => (
              <FormField
                key={label}
                label={label === "Your Role" ? "" : label}
                variant="input"
                errors={error ? "This field is required" : ""}
              >
                <Input
                  id={label}
                  type={type}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  isError={!!error}
                />
              </FormField>
            ))}
            <div className="flex flex-col gap-2">
              <FormLabel className="dark:text-white" labelText="Your Role" />
              <FormField key="Role" variant="input" errors={""}>
                <select
                  className="h-[42px] w-full border border-grey-medium px-4 py-1 outline-none focus:ring-0 focus:ring-offset-0"
                  value={customRole}
                  onChange={handleRoleChange}
                  onBlur={() => {
                    if (!roles.includes(customRole as UserRole)) {
                      setRole(UserRole.Other);
                      setCustomRole(UserRole.Other);
                    }
                  }}
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {roleOptions.map((roleOption) => (
                    <option key={roleOption.value} value={roleOption.value}>
                      {roleOption.label}
                    </option>
                  ))}
                </select>
              </FormField>
            </div>
            <div className="col-span-1 sm:col-span-2">
              <FormField
                className="w-full dark:text-white"
                label="I'm interested in participating in an early access program and providing feedback."
                variant="checkbox"
              >
                <input
                  className="dark:text-gray-700"
                  type="checkbox"
                  checked={isInterested}
                  onChange={(e) => setIsInterested(e.target.checked)}
                />
              </FormField>
            </div>
            <div className="col-span-1 pt-10 sm:col-span-2">
              <Button text="Submit" type="submit" disabled={mutation.isLoading} />
            </div>
          </div>
        </div>
        {mutation.isLoading && <p className="pt-2 dark:text-white">Attempting to register user...</p>}
      </form>
    </div>
  );
};
